import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import "./AboutBlock.css";
import {UserContext} from "../../context/UserContext";

function AboutBlock() {
  let navigate = useNavigate();
  const {isOneGame, isLoading } = useContext(UserContext);
  const [showPicture, setShowPicture] = useState(true);
  const [showTitle, setShowTitle] = useState(true);

  const pictureToggle = () => {
    setShowPicture(!showPicture);
    setShowTitle(!showTitle);
  };

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }
  return (
    <div className="about-block">
      <div className="about-inner-block main-container">
        <div className="about-block-text">
          <h2 className={`banner-title title ${!showTitle ? "banner-title-hover" : ""}`}>READY FOR ANOTHER GAME?</h2>
          {window.innerWidth > 767 ? (
            <>
              <p>
                {isOneGame ?
                    `We created this game platform with the thought of you, dear visitor. Indeed, here you’ll find a carefully selected game with top-quality graphics and new interesting features. You can stay just where you are and start playing. You need a mobile device or a computer - nothing more - and you will get a fantastic experience of game platform play without spending a single cent! C’mon, try it out! You really deserve it!`
                    :
                    `We created this game platform with the thought of you, dear visitor. Indeed, here you’ll find everything you like: slots, games, craps - an extensive collection of games with top-quality graphics and new interesting features. You can stay just where you are and start playing. You need a mobile device or a computer - nothing
                more - and you will get a fantastic experience of game platform play without spending a single cent! C’mon, try it out! You really deserve it!`
                }
              </p>
              <button
                className="main-btn"
                onClick={() => {
                  navigate("/about");
                }}
                onMouseOver={pictureToggle}
                onMouseOut={pictureToggle}
              >
                read more
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="about-empty-text"></div>
      </div>
      <div className="about-block-image-container main-container">
        <div className="about-empty-image"></div>
        <div className="about-block-image">
          {window.innerWidth <= 767 ? (
            <img width="802" height="1081" className="about-image-desktop" src="/assets/images/home/about-hover.webp" alt="" />
          ) : showPicture ? (
            <img width="802" height="1081" className="about-image-desktop" src="/assets/images/home/about.webp" alt="" />
          ) : (
            <img width="802" height="1081" className="about-image-desktop" src="/assets/images/home/about-hover.webp" alt="" />
          )}
        </div>
      </div>
      {window.innerWidth <= 767 ? (
        <div className="main-container">
          <p>
            We created this game platform with the thought of you, dear visitor. Indeed, here you’ll find everything you like: slots, games, craps - an extensive collection of
            games with top-quality graphics and new interesting features. You can stay just where you are and start playing. You need a mobile device or a computer - nothing more -
            and you will get a fantastic experience of game platform play without spending a single cent! C’mon, try it out! You really deserve it!
          </p>
          <button
            className="main-btn about-btn"
            onClick={() => {
              navigate("/about");
            }}
            onMouseOver={pictureToggle}
            onMouseOut={pictureToggle}
          >
            read more
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AboutBlock;
