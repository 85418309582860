import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { UserProvider } from "../context/UserContext";

import OpenPageOnTop from "./OpenPageOnTop";
import Header from "./Header";
import Home from "../pages/Home";
import Play from "../pages/Play";
import About from "../pages/About";
import Category from "../pages/Category";
import Contact from "../pages/Contact";
import OptOut from "../pages/OptOut";
import Userspage from "../pages/Userspage";
import Privacy from "../pages/legal/Privacy";
import Terms from "../pages/legal/Terms";
import Cookies from "../pages/legal/Cookies";
import Disclaimer from "../pages/legal/Disclaimer";
import Lp from "../pages/Lp";
import Top10 from "../pages/Top10";
import Top5 from "../pages/Top5";
import AllGames from "../pages/All-games";
import PageNotFound from "../pages/PageNotFound";
import PrivateRoute from "./PrivateRoute";

import "./App.css";

function App() {
  const [cookies, setCookie] = useCookies(["is18"]);

  if (cookies.is18 === undefined) {
    setCookie("is18", "false", { path: "/" });
  }

  const pages = [
    { path: "/", element: <Home is18={cookies.is18} /> },
    { path: "/home/", element: <Home is18={cookies.is18} /> },
    { path: "/top10/", element: <Top10 is18={cookies.is18} /> },
    { path: "/top5/", element: <Top5 is18={cookies.is18} /> },
    { path: "/all-games/", element: <AllGames is18={cookies.is18} /> },
    { path: "/categories/:categoryUrl/", element: <Category accepted={cookies.accepted} /> },
    { path: "/gb/", element: <Lp is18={cookies.is18} /> },
    { path: "/play/:gameUrl", element: <Play is18={cookies.is18} /> },
    { path: "/about/", element: <About /> },
    { path: "/contact/", element: <Contact /> },
    { path: "/opt-out/", element: <OptOut /> },
    { path: "/userspage/", element:<PrivateRoute element={<Userspage />} /> },
    { path: "/privacy/", element: <Privacy /> },
    { path: "/terms/", element: <Terms /> },
    { path: "/cookies/", element: <Cookies /> },
    { path: "/disclaimer/", element: <Disclaimer /> },
    // { path: "/page404", element: <PageNotFound /> },
    { path: '*', element: <PageNotFound /> },
    // { path: "*", element: <Home is18={cookies.is18} /> },
    { path: "/play/*", element: <PageNotFound /> },
  ];

  return (
    <Router>
      <UserProvider>
        <div className="App">
          <OpenPageOnTop />
          <Header />
          <Routes>
            {pages.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
