import React, { useContext, useState } from "react";
import { useUser } from "../../customHooks/userHooks";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import "./FirstScreen.css";
import "../owl-carousel.css";

function FirstScreen() {
  let navigate = useNavigate();
  const {isOneGame, isLoading, openRegisterPopup } = useContext(UserContext);
  const [showPicture, setShowPicture] = useState(true);
  const [showTitle, setShowTitle] = useState(true);

  const pictureToggle = () => {
    setShowPicture(!showPicture);
    setShowTitle(!showTitle);
  };

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }
  return (
    <>
      <div className="first-screen-banner">
        <img width="1920" height="1147" src="/assets/images/home/first-screen-bg.webp" className="display-banner" alt="banner" />
        <img width="375" height="843" src="/assets/images/home/first-screen-bg-tablet.webp" className="tablet-banner" alt="banner" />
        <img width="375" height="843" src="/assets/images/home/first-screen-bg-mobile.webp" className="mobile-banner" alt="banner" />
        {/* <img width='375' height='843' src="/assets/images/home/first-screen-bg-mobile.webp" className="mobile-banner" onClick={() => { navigate(`/play/0`); }} alt="banner" /> */}
        <div className="first-screen-mobile">
          <div className="banner-container">
            <div className="banner-img-container">
              {window.innerWidth <= 767 ? (
                <img width="1531" height="1195" src="/assets/images/home/first-screen-banner-hover.webp" className="first-screen-banner" alt="banner" />
              ) : showPicture ? (
                <img width="1531" height="1195" src="/assets/images/home/first-screen-banner.webp" className="first-screen-banner" alt="banner" />
              ) : (
                <img width="1531" height="1195" src="/assets/images/home/first-screen-banner-hover.webp" className="first-screen-banner" alt="banner" />
              )}
            </div>
            <div className="empty-banner"></div>
          </div>
          <div className="banner-text-container main-container">
            <div className="empty-text-banner"></div>
            <div className="banner-text-block">
              <h1 className={`banner-title title ${!showTitle ? "banner-title-hover" : ""}`}>SEE WHAT WE HAVE ON THE FUN PLATE</h1>
              {window.innerWidth > 767 ? (
                <p className="banner-text">
                  {isOneGame ?
                        `Hey, dear guest! You really want to hear this: we prepared for you a big plate with the most delicious game on the net. In Winnerplayslots Game Platform, you will find
                  a game that is entirely free. Let’s not hide, and there are no monetary prizes. But the game is not about money after all. The main point is
                  entertainment, and you’ll get it in full. So join us right now, spin the wheel and enjoy!`
                      :
                      `Hey, dear guest! You really want to hear this: we prepared for you a big plate of the most delicious games on the net. In Winnerplayslots Game Platform, you will find
                  any kind of games that are entirely free. Let’s not hide, and there are no monetary prizes. But the game is not about money after all. The main point is
                  entertainment, and you’ll get it in full. So join us right now, spin the wheel and enjoy!`
                  }
                </p>
              ) : (
                ""
              )}
              <button
                className="banner-btn main-btn"
                onClick={() => {
                  navigate(`/play/${isOneGame ? 11 : 0}`);
                }}
                onMouseOver={pictureToggle}
                onMouseOut={pictureToggle}
              >
                Play Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth <= 767 ? (
        <p className="banner-text main-container">
          Hey, dear guest! You really want to hear this: we prepared for you a big plate of the most delicious games on the net. In Winnerplayslots Game Platform, you will find any
          kind of games that are entirely free. Let’s not hide, and there are no monetary prizes. But the game is not about money after all. The main point is entertainment, and
          you’ll get it in full. So join us right now, spin the wheel and enjoy!
        </p>
      ) : (
        ""
      )}
    </>
  );
}

export default FirstScreen;
