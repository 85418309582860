import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSClick } from "../customHooks/sclickHooks";
import { top5GamesList } from "../data/top5games.js";
import { top5GameLinkArr } from "../data/gamesUrlList";
import Footer from "../components/Footer";

import "./Top5.css";

function Top5() {
  let navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState();
  const { showSClickId, sClickIdFrameSrc } = useSClick();

  useEffect(() => {
    document.title = "Winnerplayslots - Top 5";

    const contentBlock = document.querySelector(".top-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  function formatedGameName(str) {
    return str.trim().toLowerCase().replace(/\s/g, "-").replace("’", "");
  }

  return (
    <div className="top-page-body top5-page-body" style={{ minHeight: `${contentHeight}px` }}>
      {window.innerWidth > 700 ? <img className="top5-games-background" src="/assets/images/top-10-bg.webp" alt="background" /> : null}
      <div className="top5-main-container main-container">
        <h1 className="top5-games-title title">Top 5 free online games</h1>
        <div className="top5-games-wrap">
          {!top5GamesList.length ? (
            <h3>Sorry, something went wrong. Please try later</h3>
          ) : (
            top5GamesList.map((item, i) => (
              <div className="game-item" key={Math.random()}>
                <div className="game-item__left-block">
                  <div className="image-block">
                    <img src={`/assets/images/top5-games/${formatedGameName(item.gameName)}.webp`} className="game-img" alt="Game Image" />
                  </div>
                  <div className="rankings">
                    <div className="votes-block">
                      <div className="stars-container">
                        {[...Array(5).keys()].map((num) => (
                          <img key={num} src="/assets/images/top5-games/star.png" className="star-img" alt="Star Icon" />
                        ))}
                      </div>
                      <p>Votes ({item.votes})</p>
                    </div>
                    <div className="rating-block">
                      <p>Score</p>
                      <p className="score-number">{item.scoreNumber}</p>
                    </div>
                  </div>
                </div>
                <div className="game-item__right-block">
                  <div className="game-item_right-block__text">
                    <p className="highlights">Highlights</p>
                    <p className="highlights-description">{item.highlights}</p>
                  </div>
                  {/*<button className="top5-play-btn" onClick={() => { navigate(`/play/${top5GameLinkArr[i]}`); }}>Play Now</button>*/}
                  <button
                    className="top5-play-btn"
                    onClick={() => {
                      navigate(`/play/${top5GameLinkArr[i]}`);
                    }}
                  >
                    Play Now
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Footer />

      <React.Fragment>{showSClickId ? <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe> : null}</React.Fragment>
    </div>
  );
}

export default Top5;
