import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { registerUser } from "../../data/dataApi";
import IntlTelInput from "react-intl-tel-input";

import "react-intl-tel-input/dist/main.css";
import "./SignInPopup.css";

function SignInPopup({ countryData, loginPopupStatus = false }) {
  const { closeRegisterPopup, userLogged, userRegistered, showRegisterBtn, setShowRegisterBtn } = useContext(UserContext);
  const [SignInForm, setSignInForm] = useState(loginPopupStatus);

  let [nameValue, setNameValue] = useState();
  let [phoneValue, setPhoneValue] = useState();
  let [emailValue, setEmailValue] = useState();
  let [passwordValue, setPasswordValue] = useState();
  let [countryValue, setCountryValue] = useState();
  let [countryCodeValue, setCountryCodeValue] = useState();

  let [errorName, setErrorName] = useState(true);
  let [errorPhone, setErrorPhone] = useState(true);
  let [errorEmail, setErrorEmail] = useState(true);
  let [errorPassword, setErrorPassword] = useState(true);
  let [errorTerms, setErrorTerms] = useState(true);
  // let [errorMailer, setErrorMailer] = useState(true);

  const showLoginForm = () => {
    setSignInForm(true);
    resetForm();
  };

  const showSignInForm = () => {
    setSignInForm(false);
    resetForm();
  };

  const resetForm = () => {
    setErrorName(false);
    setErrorPhone(true);
    setErrorEmail(false);
    setErrorPassword(false);
    setErrorTerms(false);
    // setErrorMailer(false);

    document.getElementById("email").value = "";
    document.getElementById("password").value = "";

    // document.querySelector('input[name="terms"]:checked').checked;
  };

  const onEmailUpdate = () => {
    let value = document.getElementById("email").value.toLowerCase();
    let errorMsg = document.getElementById("email_error");

    const validateEmail = (email) => {
      let re =
        /^(?:[a-z0-9+\/= _{|}-]+(?:\.[a-z0-9+\/= _{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      // let re = /^([a-z0-9])+(([a-z0-9_\.-])*([a-z0-9])+)*@([a-z0-9])+(([a-z0-9-\.])*([a-z0-9])+)*\.([a-z]){2,}$/;
      return re.test(email);
    };

    if (value === "") {
      errorMsg.textContent = `Enter Your email`;
      setErrorEmail(true);
      setEmailValue("");
    } else if (!validateEmail(value)) {
      errorMsg.textContent = `Enter correct email`;
      setErrorEmail(true);
      setEmailValue("");
    } else {
      setErrorEmail(false);
      setEmailValue(value);
    }
  };

  const onNameUpdate = () => {
    let value = document.getElementById("name").value;
    let errorMsg = document.getElementById("fullname_error");

    const nameRegex = /^[a-zA-Z][a-zA-Z'\-]*[a-zA-Z] [a-zA-Z][a-zA-Z'\-]*[a-zA-Z]$/; //eslint-disable-line

    if (!nameRegex.test(value) || value === "") {
      errorMsg.textContent = `Enter First and Last name`;
      setErrorName(true);
    } else {
      setErrorName(false);
      setNameValue(value);
    }
  };

  const onPasswordUpdate = () => {
    let value = document.getElementById("password").value;
    let errorMsg = document.getElementById("password_error");

    if (value === "") {
      errorMsg.textContent = `Enter password`;
      setErrorPassword(true);
    } else if (value.length < 8) {
      errorMsg.textContent = `Min with: 8 symbols`;
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
      setPasswordValue(value);
    }
  };

  const onTermsUpdate = () => {
    let value = document.querySelector('input[name="terms"]:checked').value;
    let errorMsg = document.getElementById("terms_error");

    if (value === "not set") {
      errorMsg.textContent = `This checkbox is required`;
      setErrorTerms(true);
    } else {
      setErrorTerms(false);
    }
  };

  // const onMailerUpdate = () => {
  //   let value = document.querySelector('input[name="mailer"]:checked').value;
  //   let errorMsg = document.getElementById('mailer_error');

  //   if (value === 'not set') {
  //     errorMsg.textContent = `This checkbox is required`;
  //     setErrorMailer(true);
  //   } else {
  //     setErrorMailer(false);
  //   }
  // };

  const sendData = (e) => {
    e.preventDefault();
    const url = registerUser(e.target.id);

    onEmailUpdate();
    onPasswordUpdate();

    if (!SignInForm) {
      onNameUpdate();
      onTermsUpdate();
      // onMailerUpdate();

      if (!errorPhone) {
        // if (!errorName && !errorEmail && !errorPassword && !errorTerms && !errorMailer) {
        if (!errorName && !errorEmail && !errorPassword && !errorTerms) {
          const formData = new FormData();
          formData.append("user[email]", emailValue);
          formData.append("user[password]", passwordValue);
          formData.append("user[user_name]", nameValue);
          formData.append("user[phone_number]", phoneValue.replace(/[)( -]/g, ""));
          formData.append("user[country]", countryValue);
          formData.append("user[country_code]", countryCodeValue);

          const options = {
            method: "POST",
            body: formData,
          };

          setShowRegisterBtn(false);

          fetch(url, options)
            .then((response) => response.json())
            .then((result) => {
              if (result.status === "success") {
                if (e.target.id === "register") {
                  userRegistered();
                } else {
                  userLogged();
                }
              } else {
                let errorEmail = document.getElementById("email_error");
                let errorPass = document.getElementById("password_error");
                if (result.message === "Password is incorrect") {
                  errorPass.textContent = result.message;
                  setErrorPassword(true);
                } else {
                  errorEmail.textContent = result.message;
                  setErrorEmail(true);
                  setShowRegisterBtn(true);
                }
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      } else {
        let errorPhone = document.getElementById("phone_error");
        errorPhone.textContent = "Enter Your Phone Number";
      }
    } else {
      if (!errorEmail && !errorPassword) {
        const formData = new FormData();
        formData.append("user[email]", emailValue);
        formData.append("user[password]", passwordValue);

        const options = {
          method: "POST",
          body: formData,
        };

        fetch(url, options)
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              if (e.target.id === "register") {
                userRegistered();
              } else {
                userLogged();
              }
            } else {
              let errorEmail = document.getElementById("email_error");
              let errorPass = document.getElementById("password_error");
              if (result.message === "Password is incorrect") {
                errorPass.textContent = result.message;
                setErrorPassword(true);
              } else {
                errorEmail.textContent = result.message;
                setErrorEmail(true);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  return (
    <div className="sign-up-popup">
      <div className="sign-up-overlay" onClick={() => closeRegisterPopup()}></div>
      <div className="sign-up-body">
        <button className="close-popup-btn" onClick={() => closeRegisterPopup()}>
          <img src="/assets/images/close-icon.svg" alt="close button" />
        </button>

        <>{SignInForm ? <h2 className="title">Login</h2> : <h2 className="title">Sign in with</h2>}</>

        <form action="#" method="post">
          <>
            {!SignInForm ? (
              <div>
                <div className="input-cover">
                  {/* <label htmlFor="name">Full Name</label> */}
                  <input type="text" id="name" onChange={onNameUpdate} placeholder="Full Name" />
                  <div className={errorName ? "error_message-show" : "error_message-hide"} id="fullname_error"></div>
                </div>
                <div className="input-cover">
                  {/* <label htmlFor="phone-number">Your Phone</label> */}
                  <IntlTelInput
                    preferredCountries={[countryData.code]}
                    fieldId={"phone-number"}
                    onPhoneNumberChange={(isValid, a, b, number) => {
                      let name = b.name.split("(")[0];
                      name = name.trim();
                      let countryCode = b.iso2.toString();
                      setErrorPhone(false);
                      if (isValid) {
                        setPhoneValue(number);
                        setCountryValue(name);
                        setCountryCodeValue(countryCode);
                        setErrorPhone(false);
                      } else {
                        setErrorPhone(true);
                      }
                    }}
                    onPhoneNumberBlur={(isValid) => {
                      setErrorPhone(!isValid);
                    }}
                  />
                  <div className={errorPhone ? "error_message-show" : "error_message-hide"} id="phone_error"></div>
                </div>
              </div>
            ) : null}
          </>

          <div className="input-cover">
            {/* <label htmlFor="email">Your email</label> */}
            <input type="email" id="email" onChange={onEmailUpdate} placeholder="Email" />
            <div className={errorEmail ? "error_message-show" : "error_message-hide"} id="email_error"></div>
          </div>

          <div className="input-cover">
            {/* <label htmlFor="password">Password</label> */}
            <input type="password" id="password" onChange={onPasswordUpdate} placeholder="Password" />
            <div className={errorPassword ? "error_message-show" : "error_message-hide"} id="password_error"></div>
          </div>

          <>
            {!SignInForm ? (
              <div>
                <div className="terms-checkbox-cover">
                  <label htmlFor="terms">
                    <input type="checkbox" id="terms" name="terms" value="yes" onChange={onTermsUpdate} />
                    <span className="checkbox-text">
                      I agree with{" "}
                      <Link target="_blank" to="/terms/">
                        Terms & Conditions
                      </Link>
                    </span>
                    <span className="box checkbox"></span>
                  </label>

                  <label className="hidden">
                    <input type="checkbox" name="terms" value="not set" defaultChecked />
                    <span className="box checkbox"></span>
                  </label>

                  <div className={errorTerms ? "error_message-show" : "error_message-hide"} id="terms_error"></div>
                </div>

                {/* <div className="terms-checkbox-cover">
                  <label htmlFor="mailer">
                    <input type="checkbox" id="mailer" name="mailer" value="yes" onChange={onMailerUpdate} />
                    <span className="checkbox-text">I would like to receive exclusive promotions via Email & SMS</span>
                    <span className="box checkbox"></span>
                  </label>

                  <label className="hidden">
                    <input type="checkbox" name="mailer" value="not set" defaultChecked />
                    <span className="box checkbox"></span>
                  </label>

                  <div className={errorMailer ? 'error_message-show' : 'error_message-hide'} id="mailer_error" ></div>
                </div> */}
              </div>
            ) : null}
          </>

          <>
            {SignInForm ? (
              <button type="submit" id="login" className="main-btn" onClick={sendData}>
                Login
              </button>
            ) : (
              <button type="submit" id="register" className={`main-btn ${showRegisterBtn ? "" : "disable"}`} disabled={showRegisterBtn ? "" : true} onClick={sendData}>
                Sign In
              </button>
            )}
          </>
        </form>

        <>
          {SignInForm ? (
            <p className="form-text">
              Don't have an account? <button onClick={showSignInForm}>Sign In</button>
            </p>
          ) : (
            <p className="form-text">
              Do you already have an account? <button onClick={showLoginForm}>Log In</button>
            </p>
          )}
        </>
      </div>
    </div>
  );
}

export default SignInPopup;
