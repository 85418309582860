import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import './ThankYouPopup.css';

function ThankYouPopup() {
  const { closeThanksPopup } = useContext(UserContext);

  return (
      <>
          <div className="thank-you-popup" onClick={() => closeThanksPopup()}>
              <div className="thank-you-overlay"></div>
              <div className="thank-you-body">
                  <button className="close-popup-btn">
                      <img src="/assets/images/close-icon.svg" alt="close button" />
                  </button>
                  <h2 className='title'>Thank you <br /> for signing up!</h2>
                  <p>Check your email & SMS for your secret welcome bonus!</p>
                  <img className="thank-you-bg" src="/assets/images/thankyou.webp" alt="background" />
              </div>
          </div>
      </>
  );
}

export default ThankYouPopup;
