import React, { useContext } from 'react';
import { useUser } from '../../customHooks/userHooks';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

import './Steps.css';

function Steps() {
  let navigate = useNavigate();
  const { openRegisterPopup } = useContext(UserContext);
  const { canStartPlay } = useUser();

  return (
    <>
      <div className="lp-steps-banner">
        <img width='1920' height='1147' src="/assets/images/lp/lp-steps-bg.webp" className="lp-display-banner" alt="banner" />
        <div className="steps-main-container">
          <div className="steps-inner-container">
            <div className="step-block">
              {window.innerWidth > 1024 ?
                <div className="step-text-container">
                  <img src="/assets/images/lp/step_1-text.svg" className='step-text-bg' alt="background" />
                  <div className="step-text">
                    <h3>register</h3>
                    <p>Now, spend a few minutes for creating your free account and you can enjoy our games!</p>
                  </div>
                </div> :
                <div className="step-text-container">
                  <h3>register</h3>
                  <img src="/assets/images/lp/step-text-mobile.svg" className='step-text-bg' alt="background" />
                  <div className="step-text step-1-text">
                    <p>Now, spend a few minutes for creating your free account and you can enjoy our games!</p>
                  </div>
                </div>
              }
              <img src="/assets/images/lp/step_1.webp" className='step-bg' alt="background" />
            </div>
            <div className="step-block">
              {window.innerWidth > 1024 ?
                <div className="step-text-container">
                  <img src="/assets/images/lp/step_2-text.svg" className='step-text-bg' alt="background" />
                  <div className="step-text step-2-text">
                    <h3>start playing</h3>
                    <p>Nothing can stop you now: Play wherever you are with your mobile device or a computer. </p>
                  </div>
                </div> :
                <div className="step-text-container">
                  <h3>start playing</h3>
                  <img src="/assets/images/lp/step-text-mobile.svg" className='step-text-bg' alt="background" />
                  <div className="step-text step-2-text">
                    <p>Nothing can stop you now: Play wherever you are with your mobile device or a computer. </p>
                  </div>
                </div>
              }
              {window.innerWidth > 1024 ?
                <img src="/assets/images/lp/step_2.webp" className='step-bg step-2-bg' alt="background" /> :
                <img src="/assets/images/lp/step_2-mobile.webp" className='step-bg step-2-bg' alt="background" />
              }
            </div>
            <div className="step-block">
              {window.innerWidth > 1024 ?
                <div className="step-text-container">
                  <img src="/assets/images/lp/step_3-text.svg" className='step-text-bg' alt="background" />
                  <div className="step-text step-3-text">
                    <h3>have fun</h3>
                    <p>We suggest that you check your email or SMS regularly: we have plenty of updates for you!</p>
                  </div>
                </div> :
                <div className="step-text-container">
                  <h3>have fun</h3>
                  <img src="/assets/images/lp/step-text-mobile.svg" className='step-text-bg' alt="background" />
                  <div className="step-text step-3-text">
                    <p>We suggest that you check your email or SMS regularly: we have plenty of updates for you!</p>
                  </div>
                </div>
              }
              <img src="/assets/images/lp/step_3.webp" className='step-bg step-3-bg' alt="background" />
            </div>
          </div>
          <button className='lp-btn main-btn' onClick={() => { navigate(`/play/0`); }}>Play Now</button>
        </div>
      </div>
    </>
  );
}

export default Steps;
