import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSClick } from '../customHooks/sclickHooks';
import Footer from '../components/Footer';

import './All-games.css';

function AllGames() {
  let navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState();
  const { showSClickId, sClickIdFrameSrc, } = useSClick();

  useEffect(() => {
    document.title = 'Winnerplayslots - All Games';

    const contentBlock = document.querySelector('.top-page-body');
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
      <div className="top-page-body top5-page-body" style={{ minHeight: `${contentHeight}px` }}>
        {window.innerWidth > 700 ? <img className="top5-games-background" src="/assets/images/all-games-bg.webp" alt="background" /> : null}
          <div className="top10-main-container main-container">
            <div className="title-block">
                <h2 className="banner-title title all-games">EVERY COOL GAME YOU’VE DREAMED OF
                </h2>
            </div>

            <div className="categories-menu-cover">
                <nav className="categories-menu">
                    <ul>
                        <li>
                            <Link to="/all-games/">
                                <span className="img-div"><img src="/assets/images/all-games/all-games-icon.png" alt="icon" width="74" height="45"/></span>
                                <span>All Games</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/sport/">
                                <span className="img-div"><img src="/assets/images/all-games/01-all-games-icon.png" alt="icon" width="62" height="65"/></span>
                                <span>Sport</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/blackjack/">
                                <span className="img-div"><img src="/assets/images/all-games/02-all-games-icon.png" alt="icon" width="60" height="60"/></span>
                                <span>black jack</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/roulette/">
                                <span className="img-div"><img src="/assets/images/all-games/03-all-games-icon.png" alt="icon" width="70" height="70"/></span>
                                <span>roulette</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/slots/">
                                <span className="img-div"><img src="/assets/images/all-games/04-all-games-icon.png" alt="icon" width="75" height="30"/></span>
                                <span>slots</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/poker/">
                                <span className="img-div"><img src="/assets/images/all-games/05-all-games-icon.png" alt="icon" width="62" height="60"/></span>
                                <span>poker</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories/jackpot/">
                                <span className="img-div"><img src="/assets/images/all-games/06-all-games-icon.png" alt="icon" width="63" height="50"/></span>
                                <span>jackpot</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className="all-games-content">
                <div className="lp-games-cover" >
                    <div className="games-label">
                        <span>Sport</span>
                    </div>
                    <div className="lp-games">
                        <div className="all-game-icons-cover" onClick={() => {navigate(`/play/12/`)} }>
                            <img src="/assets/images/all-games/pride-fight.png" alt="game"/>
                            <button className="play-game-btn" onClick={() => {navigate(`/play/12/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/13/`)} }>
                            <img src="/assets/images/all-games/football.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/13/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/14/`)} }>
                            <img src="/assets/images/all-games/basketball.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/14/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={() => {navigate(`/play/15/`)} }>
                            <img src="/assets/images/all-games/football-champions-cup.png" alt="game"/>
                            <button className="play-game-btn" onClick={() => {navigate(`/play/15/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/16/`)} }>
                            <img src="/assets/images/all-games/penalty-series.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/16/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/17/`)} }>
                            <img src="/assets/images/all-games/hugo-goal.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/17/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/18/`)} }>
                            <img src="/assets/images/all-games/hockey-league-wild-match.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/18/`)} }>Play now</button>
                            <span id="black-jack"></span>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/19/`)} }>
                            <img src="/assets/images/all-games/gold-trophy-2.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/19/`)} }>Play now</button>
                        </div>
                    </div>
                </div>

                <div className="lp-games-cover">
                    <div className="games-label">
                        <span>black jack</span>
                    </div>

                    <div className="lp-games">
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/20/`)} }>
                            <img src="/assets/images/all-games/blackjack.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/20/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/21/`)} }>
                            <img src="/assets/images/all-games/blackjack-lucky7.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/21/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/22/`)} }>
                            <img src="/assets/images/all-games/blackjack-double-exposure.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/22/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/23/`)} }>
                            <img src="/assets/images/all-games/blackjack-vip.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/23/`)} }>Play now</button>
                        </div>
                        <span id="roulette"></span>

                    </div>
                </div>


                <div className="lp-games-cover">
                    <div className="games-label">
                        <span>roulette</span>
                    </div>
                    <div className="lp-games">
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/24/`)} }>
                            <img src="/assets/images/all-games/bonanza-wheel.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/24/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/25/`)} }>
                            <img src="/assets/images/all-games/american-roulette-3d.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/25/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/26/`)} }>
                            <img src="/assets/images/all-games/european-roullette-high-stakes.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/26/`)} }>Play now</button>
                        </div>

                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/27/`)} }>
                            <img src="/assets/images/all-games/wheel-of-time.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/27/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/28/`)} }>
                            <img src="/assets/images/all-games/magic-wheel.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/28/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/29/`)} }>
                            <img src="/assets/images/all-games/european-roulette-pro.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/29/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/30/`)} }>
                            <img src="/assets/images/all-games/european-roullette.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/30/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/31/`)} }>
                            <img src="/assets/images/all-games/super-wheel.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/31/`)} }>Play now</button>
                        </div>
                        <span id="slots"></span>

                    </div>
                </div>


                <div className="lp-games-cover">
                    <div className="games-label">
                        <span>slots</span>
                    </div>
                    <div className="lp-games">
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/32/`)} }>
                            <img src="/assets/images/all-games/deuces-wild.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/32/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/33/`)} }>
                            <img src="/assets/images/all-games/gemix.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/33/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/34/`)} }>
                            <img src="/assets/images/all-games/grim-muerto.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/34/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/35/`)} }>
                            <img src="/assets/images/all-games/jolly-roger.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/35/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/36/`)} }>
                            <img src="/assets/images/all-games/wild-north.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/36/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover" onClick={ () => {navigate(`/play/37/`)} }>
                            <img src="/assets/images/all-games/fire-joker.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/37/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/38/`)} }>
                            <img src="/assets/images/all-games/jolly-treasures.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/38/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/39/`)} }>
                            <img src="/assets/images/all-games/lucky-girls.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/39/`)} }>Play now</button>
                        </div>
                        <span id="poker"></span>

                    </div>
                </div>


                <div className="lp-games-cover">
                    <div className="games-label">
                        <span>poker</span>
                    </div>
                    <div className="lp-games">
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/40/`)} }>
                            <img src="/assets/images/all-games/texas-holdem.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/40/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/41/`)} }>
                            <img src="/assets/images/all-games/baccarat.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/41/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/42/`)} }>
                            <img src="/assets/images/all-games/texas-holdem-bonus.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/42/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/43/`)} }>
                            <img src="/assets/images/all-games/oasis-poker.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/43/`)} }>Play now</button>
                        </div>
                        <span id="jackpot"></span>
                    </div>
                </div>

                <div className="lp-games-cover">
                    <div className="games-label">
                        <span>jackpot</span>
                    </div>
                    <div className="lp-games">
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/44/`)} }>
                            <img src="/assets/images/all-games/big-win-cat.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/44/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/45/`)} }>
                            <img src="/assets/images/all-games/super-flip.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/45/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/46/`)} }>
                            <img src="/assets/images/all-games/guns-linger-reloaded.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/46/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/47/`)} }>
                            <img src="/assets/images/all-games/chilli-bang.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/47/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/48/`)} }>
                            <img src="/assets/images/all-games/shadow-of-luxor.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/48/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/49/`)} }>
                            <img src="/assets/images/all-games/atlantis.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/49/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/50/`)} }>
                            <img src="/assets/images/all-games/neonstaxx.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/50/`)} }>Play now</button>
                        </div>
                        <div className="all-game-icons-cover"  onClick={ () => {navigate(`/play/51/`)} }>
                            <img src="/assets/images/all-games/royal-cash.png" alt="game"/>
                            <button className="play-game-btn" onClick={ () => {navigate(`/play/51/`)} }>Play now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        <React.Fragment>
          {showSClickId ? (
              <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe>
          ) : (null)}
        </React.Fragment>
      </div>
  );
}

export default AllGames;
