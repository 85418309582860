export const top10GameLinkArr = [0, 1, 2, 7, 8, 3, 10, 6, 5, 9];
export const lpGameLinkArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const top5GameLinkArr = [3, 10, 0, 8, 7];

export const gamesUrlList = [
  // 0 - Cash Vandal
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=cashvandal&gameId=370&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 1 - Banana Rock
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bananarock&gameId=347&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 2 - Cats and Cash
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=catsandcash&gameId=193&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 3 - Ninja Fruits
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=ninjafruits&gameId=253&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 4 - Money Machine
  "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs10amm&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F",
  // 5 - Chicken Chase
  "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs10chkchase&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F",
  // 6 - Fire Strike
  "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs10firestrike2&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F",
  // 7 - Naughty Girls
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/cabaret",
  // 8 - Chilli Bang
  "https://game-launcher-lux.isoftbet.com/255/904628?lang=en&cur=EUR&mode=0",
  // 9 - Pimped
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=pimped&gameId=291&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 10 - Sweet 27
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=sweet27&gameId=335&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 11 - The Playing Piano Club
  "https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=thepayingpianoclub&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True%27",
  // 12 - Pride Fight
  "https://demo.evoplay.games/demo/instant/html5/evoplay/pridefight",
  // 13 - Football
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/football",
  // 14 - Basketball
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/basketball",
  // 15 - Football Champions Cup
  "https://netent-static.casinomodule.com/games/football_mobile_html/game/football_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=football_not_mobile&showHomeButton=false&gameLocation=games%2Ffootball_mobile_html%2F&preBuiltGameRulesSupported=true&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-4268833735-USD&operatorId=netent",
  // 16 - Penalty Series
  "https://demo.evoplay.games/demo/instant/html5/evoplay/penaltyseries",
  // 17 - Hugo Goal
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=hugogoal&gameId=354&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=%22",
  // 18 - Hockey League Wild Match
  "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20hockey&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F&lang=EN&cur=USD",
  // 19 - Gold Trophy 2
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldtrophy2&gameId=246&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 20 - Blackjack
  "https://rgstorgs.stage.pariplaygames.com/pariplay?mode=fun&currency=USD&lang=EN&gameCode=PP_HTML5_Blackjack&homeUrl=&debugstate=&operatorId=PP",
  // 21 - Blackjack Lucky 7
  "https://demo.evoplay.games/demo/table/html5/evoplay/blackjack",
  // 22 - Blackjack Double Exposure
  "https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=doubleexposureblackjackmh&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True",
  // 23 - Blackjack Vip
  "https://showcase.darwininteractive.com/darwin/demo/?currency=USD&language=en-us&device=desktop&token=60164937047934.52&gameID=silvercurlewspider",
  // 24 - Bonanza Wheel
  "https://demo.evoplay.games/demo/instant/html5/evoplay/bonanzawheel",
  // 25 - American roulette 3d
  "https://demo.evoplay.games/demo/table/html5/evoplay/americanroulette",
  // 26 - European roullette highstakes
  "https://d21j22mhfwmuah.cloudfront.net/0Debug/PP_HTML5_EuropeanRoulette/index.html?gameCode=PP_HTML5_EuropeanRouletteHighStakes&token=DEMO_PP_bc0d7697-9667-4424-80dd-a85fed655822&homeUrl=&rgsUrl=https://rgstorgs.stage.pariplaygames.com&lang=EN&DebugMode=False&currencyCode=EUR&disableRotation=False&ExtraData=networkId%3dPP&HideCoins=False&CoinsDefault=True",
  // 27 - Wheel of time
  "https://demo.evoplay.games/demo/instant/html5/evoplay/wheeloftime",
  // 28 - Magic Wheel
  "https://demo.evoplay.games/demo/instant/html5/evoplay/magicwheel",
  // 29 - European roullette pro
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=europeanroulette&gameId=31&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 30 - European roullette
  "https://demo.evoplay.games/demo/table/html5/evoplay/europeanroulette",
  // 31 - Super Wheel
  "https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=superwheel&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True",
  // 32 - Deuces Wild
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=deuceswild&gameId=270&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 33 - Gemix
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=gemix&gameId=286&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 34 - Grim Muerto
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=grimmuerto&gameId=303&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 35 - Jolly Roger
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=jollyroger&gameId=40&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=",
  // 36 - Wild North
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=wildnorth&gameId=294&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 37 - Fire Joker
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=firejoker&gameId=307&lang=en&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 38 - Jolly Treasures
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/jollytreasures",
  // 39 - Lucky Girls
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/luckygirls",
  // 40 - Texas Holdem
  "https://demo.evoplay.games/demo/table/html5/evoplay/texasholdempoker3d",
  // 41 - Baccarat
  "https://demo.evoplay.games/demo/table/html5/evoplay/baccarat",
  // 42 - Texas Holdem Bonus
  "https://demo.evoplay.games/demo/table/html5/evoplay/texasholdembonus",
  // 43 - Oasis Poker
  "https://demo.evoplay.games/demo/table/html5/evoplay/oasispokerclassic",
  // 44 - Big Win Cat
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bigwincat&gameId=342&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 45 - Super Fleep
  "https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=superflip&gameId=295&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=",
  // 46 - Guns Linger Reloaded
  "https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=gunslingerreloaded&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True",
  // 47 - Chilli Bang
  "https://game-launcher-lux.isoftbet.com/255/904628?lang=en&cur=EUR&mode=0",
  // 48 - Shadow of Luxor
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/shadowofluxorjackpot",
  // 49 - Atlantis
  "https://demo.evoplay.games/demo/fullstate/html5/evoplay/atlantis",
  // 50 - Neonstaxx
  "https://netent-static.casinomodule.com/games/neonstaxx_mobile_html/game/neonstaxx_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=neonstaxx_not_mobile&gameLocation=games%2Fneonstaxx_mobile_html%2F&preBuiltGameRulesSupported=true&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-8999160028-USD&operatorId=netent",
  // 51 - ROyal Cash
  "https://game-launcher-lux.isoftbet.com/255/904612?lang=en&cur=EUR&mode=0",
];
