import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useUser } from "../customHooks/userHooks";
import { useSClick } from "../customHooks/sclickHooks";
import { top10GameLinkArr } from "../data/gamesUrlList";
import Footer from "../components/Footer";

import "./Top10.css";

function Top10() {
  let navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState();
  const { openRegisterPopup } = useContext(UserContext);
  const { canStartPlay } = useUser();
  const { showSClickId, sClickIdFrameSrc } = useSClick();

  useEffect(() => {
    document.title = "Winnerplayslots - Top 10";

    const contentBlock = document.querySelector(".top-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
    <div className="top-page-body top10-page-body" style={{ minHeight: `${contentHeight}px` }}>
      {window.innerWidth > 700 ? <img className="top10-games-background" src="/assets/images/top-10-bg.webp" alt="background" /> : null}
      <div className="top10-main-container main-container">
        <h1 className="top10-games-title title">Top 10 free online games</h1>
        <div className="top10-games-cover">
          <div className="top10-games-container">
            {[...Array(3).keys()].map((num) => (
              <div className="top10-games-image-block game-image-block" key={num}>
                <img width="600" height="349" src={`/assets/images/top10-games/top-game-${num + 1}.webp`} alt="game" />
                <div className="hover-img">
                  <button
                    onClick={() => {
                      navigate(`/play/${top10GameLinkArr[num]}`);
                    }}
                  >
                    Play Now
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="top10-games-container top10-games-middle-container">
            {[...Array(4).keys()].map((num) => (
              <div className="top10-games-image-block game-image-block" key={num}>
                <img width="432" height="432" src={`/assets/images/top10-games/top-game-${num + 4}.webp`} alt="game" />
                <div className="hover-img">
                  <button
                    onClick={() => {
                      navigate(`/play/${top10GameLinkArr[num + 3]}`);
                    }}
                  >
                    Play Now
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="top10-games-container">
            {[...Array(3).keys()].map((num) => (
              <div className="top10-games-image-block game-image-block" key={num}>
                <img width="600" height="349" src={`/assets/images/top10-games/top-game-${num + 8}.webp`} alt="game" />
                <div className="hover-img">
                  <button
                    onClick={() => {
                      navigate(`/play/${top10GameLinkArr[num + 7]}`);
                    }}
                  >
                    Play Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />

      <React.Fragment>{showSClickId ? <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe> : null}</React.Fragment>
    </div>
  );
}

export default Top10;
