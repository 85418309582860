import React, {useContext, useEffect, useState} from "react";
import Footer from "../../components/Footer";
import "./Legal.css";
import {UserContext} from "../../context/UserContext";

function Disclaimer() {
  const [contentHeight, setContentHeight] = useState();
  const {isOneGame, isLoading } = useContext(UserContext);


  useEffect(() => {
    document.title = "Winnerplayslots - Disclaimer";

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="text-page-body legal-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover main-container">
        <h1 className="title">Disclaimer</h1>
        <p>
          {isOneGame ?
              `Winnerplayslots offers a top-notch licensed game from a world-famous gaming brand. It is a social game platform where  the game is free, and we do not offer real money prizes. However, due to the character of this activity, the site is accessible only to visitors of the age of 21+ y.o. Winning in the social game platform doesn’t imply you will have the same result in real money game platforms.`
              :
              `Winnerplayslots offers a wide selection of licensed slots and games from world-famous gaming brands. It is a social game platform where all the games are free, and we do not offer real money prizes. However, due to the character of this activity, the site is accessible only to visitors of the age of 21+ y.o. Winning in the social game platform doesn’t imply you will have the same result in real money game platforms.`
          }
        </p>
        <p>
          Be responsible and mindful while playing games. Don't allow gaming to become your obsession! You must also understand that we make no warranties regarding the accuracy,
          adequacy, validity, reliability, availability, or completeness of any information on the Winnerplayslots website.
        </p>
        <p>
          This site structure allows links to third-party websites/external links. Since they are not the property of our company, we cannot monitor or verify those third-party
          links for accuracy, validity, reliability, availability, or completeness. That's why we do not guarantee that the content provided on those sites is correct, and our game
          platform will not bear any kind of responsibility for any loss caused to you by using those sites. You have to read these pages' Terms and Conditions and Privacy Policy
          before using them.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Disclaimer;
