import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Steps from '../components/lp_blocks/Steps';
import TopGames from '../components/lp_blocks/TopGames';
import TopGame from '../components/lp_blocks/TopGame';
import { useSClick } from '../customHooks/sclickHooks';

import './Home.css';

function Home() {
  const [bodyHeight, setBodyHeight] = useState();
  const { showSClickId, sClickIdFrameSrc } = useSClick();

  useEffect(() => {
    document.title = 'Winnerplayslots';
    const contentBlock = document.querySelector('#games-carousel-cover');
    setBodyHeight(contentBlock.clientHeight - 400);
  }, []);

  return (
    <div className="home-page-cover lp-home-page-cover" style={{ minHeight: `${bodyHeight}px` }}>
      <Steps />
      <TopGames />
      <TopGame />
      <div id="games-carousel-cover" style={{ minHeight: `${bodyHeight}px` }}></div>
      <Footer />

      <React.Fragment>
        {showSClickId ? (
          <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe>
        ) : (null)}
      </React.Fragment>
    </div>
  );
}

export default Home;
