import { checkAuth } from './dataApi';

export default async function checkUserAuth() {
  const url = checkAuth();
  return fetch(url).then(response => {
    const result = response.json();
    return result;
  });
}

