import React, {useContext} from "react";
import { Link, NavLink } from "react-router-dom";
import "./Footer.css";
import {UserContext} from "../context/UserContext";

function Footer({ className }) {
  const {isOneGame, isLoading } = useContext(UserContext);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <footer className={`footer ${className ? className : ""}`}>
      <div className="footer-inner-container main-container">
        <Link to="/home/" className="footer-logo-container">
          <img width="164" height="66" src="/assets/images/footer-logo.webp" className="footer-logo" alt="" />
        </Link>
        <div className="footer-cover">
          <div className="footer-content">
            <h2 className="footer-cover-title">Disclaimer</h2>
            <p>
              {isOneGame ?
                  `Winnerplayslots offers a top-notch licensed game from a world-famous gaming brand. It is a social game platform where  the game is free, and we do not offer real money prizes. However, due to the character of this activity, the site is accessible only to visitors of the age of 21+ y.o. Winning in the social game platform doesn’t imply you will have the same result in real money game platforms.`
                  :
                  `Winnerplayslots offers a wide selection of licensed slots and games from world-famous gaming brands. It is a social game platform where all the games are free, and we do not offer real money prizes. However, due to the character of this activity, the site is accessible only to visitors of the age of 21+ y.o. Winning in the social game platform doesn’t imply you will have the same result in real money game platforms.`
              }
            </p>
            <ul className="footer-nav">
              <li>
                <NavLink to="/">Home</NavLink>
                <span>|</span>
              </li>
              <li>
                <NavLink to="/about/">About</NavLink>
                <span>|</span>
              </li>
              <li>
                <NavLink to="/contact/">Contact</NavLink>
                <span>|</span>
              </li>
              <li>
                <NavLink to="/privacy/">Privacy Policy</NavLink>
                <span>|</span>
              </li>
              <li>
                <NavLink to="/terms/">Terms & Conditions</NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="empty-footer-block"></div> */}
      </div>
    </footer>
  );
}

export default Footer;
