export const top5GamesList = [
  {
    gameName: "Ninja fruits", highlights: "Fruits and ninjas? Oh, yes! It is a colorful Orient-themed slot that will bring you to the next level of fun!",votes: "5373",scoreNumber: "9.9"
  },
  {
    gameName: "Sweet 27", highlights: "It is a fancy realm that even Willi Wonka couldn't imagine. Keep the biscuit barrel close to you playing it!",votes: "5268",scoreNumber: "9.8"
  },
  {
    gameName: "Cash vandal", highlights: "Spray cans will be helpful here. You'll go to London, Paris and Berlin where you can show your graffiti skills.",votes: "5202",scoreNumber: "9.6"
  },
  {
    gameName: "Chilli bang", highlights: "The cute and unique game. Spin the reels to win a top prize, a free spin round, and get the wild symbols.",votes: "5154",scoreNumber: "9.6"
  },
  {
    gameName: "Naughty girls", highlights: "Welcome the lounge with a VIP gambling club. The ladies of this saucy show are ready and waiting to entertain you.",votes: "5121",scoreNumber: "9.5"
  },
]