import {useState, useEffect, useContext} from 'react';
import checkUserAuth from '../data/userData';
import {useLocation} from "react-router-dom";
import { UserContext } from '../context/UserContext';


export const useUser = () => {
  const { pathname } = useLocation();
  const { cookies, showRegisterPopup, setShowRegisterPopup } = useContext(UserContext);

  const [isDataLoading, setDataLoading] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);

  useEffect(() => {
    checkUserAuth()
      .then((result) => {
        if (result.is_auth === true) {
          setShowRegisterBtn(false);
          setShowRegisterPopup(false);
        } else if(result.is_auth === false){
          if(pathname.split('/')[1] === "play"){
            setTimeout(() => {
              if(cookies.is18 === 'false') {
                setShowRegisterPopup(false);
              } else {
                setShowRegisterPopup(true);
              }
            }, 3000)
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [cookies.is18, pathname]);

  return {
    isDataLoading,
    setDataLoading,
    showRegisterBtn,
    setShowRegisterBtn,
    showRegisterPopup,
    setShowRegisterPopup,
    cookies
  };
};
