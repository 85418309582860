import React, {useContext, useEffect, useState} from "react";
import Footer from "../components/Footer";
import "./About.css";
import {UserContext} from "../context/UserContext";

function About() {
  const [contentHeight, setContentHeight] = useState();
  const {isOneGame, isLoading } = useContext(UserContext);

  useEffect(() => {
    document.title = "Winnerplayslots - About";
    // document.querySelector("meta[name='description']").setAttribute('content', "Discover a fantastic universe of free games full of adventures and possibilities. Play for free on your mobile or computer.");

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight);
  }, [setContentHeight]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="text-page-body about-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="about-container main-container">
        <div className="about-text-container">
          <h2 className="about-title title">NET, CLEAN AND COZY place</h2>
          <p>
            We all share a common affection for the game. This is love and passion, which is difficult to explain. But you understand what we mean - that's why you are here. So let
            us make a little presentation about the venue you're about to enter.
          </p>
          <p>
            Yes, it's not just any place, it's a social game platform. It means you can play here at ease without even bothering you about losing money. Because everything is free!
          </p>
          <p>
            We all know how it can be painful to lose cash on gaming. But what if you just want to have fun by just playing and not betting money? We found it an excellent idea and
            created Winnerplayslots - a cozy and safe place for every guest.
          </p>
          <p>
            {isOneGame ?
                `Our venue features a carefully selected game based on the rates from the users. To boost your experience, we make sure it is updated regularly.`
                :
                `Our venue features the widest selection of slot machines from global brands. To boost your experience, we update our content with new cool games. Just take a look at what we can offer. Here you can try yourself in different slots or games and test new strategies in playing baccarat or poker - for your fun and pleasure.`
            }
          </p>
          <p>It sounds incredible and awesome, we know. Now it's time for you to enjoy the game! </p>
        </div>
        <div className="about-img-container">
          <img src="/assets/images/about-main.webp" alt="about" className="about-image" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
