import React, {useState, useEffect, useContext} from "react";
import Footer from "../components/Footer";
import FirstScreen from "../components/home_blocks/FirstScreen";
import AboutBlock from "../components/home_blocks/AboutBlock";
import TopGames from "../components/home_blocks/TopGames";
import TopGame from "../components/home_blocks/TopGame";

import "./Home.css";
import {UserContext} from "../context/UserContext";

function Home() {
  const [bodyHeight, setBodyHeight] = useState();
    const {isOneGame, isLoading } = useContext(UserContext);

  useEffect(() => {
    document.title = "Winnerplayslots - Home";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Winnerplayslots is a cozy and safe place full of coolest games. Enjoy free-to-play social games with no real money prizes. Sign in today, spin the wheel and enjoy!"
      );
    const contentBlock = document.querySelector("#games-carousel-cover");
    setBodyHeight(contentBlock.clientHeight - 400);
  }, []);

  // const showAgePopupHandler = (e) => {
  //   let game = e.target.id.replace('game-', '');

  //   setTheGame(game);
  //   setShowAgepopup(true);
  // }

  return (
    <div className="home-page-cover">
      <FirstScreen />
      <AboutBlock />
        {!isOneGame && <TopGames />}
      <TopGame />

      <div id="games-carousel-cover" style={{ minHeight: `${bodyHeight}px` }}></div>

      <Footer className={"home-footer"} />
    </div>
  );
}

export default Home;
