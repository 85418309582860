import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Legal.css";

function Cookies() {
  const [page, setPage] = useState("/");

  useEffect(() => {
    document.title = "Winnerplayslots - Cookies Policy";
    setPage(localStorage.getItem("page"));
  }, []);

  return (
    <div>
      <div className="text-page-body legal-page-body">
        <div className="content-cover main-container">
          <h1 className="title">Cookies Policy</h1>
          <p>
            While visiting our Social Gaming Platform website, the system will automatically collect information about your steps: your browser, IP address, and the referring
            Website. This data collection may be made together with our suppliers or partners. We may obtain general demographic or usage information from them about our website
            players. We do not need this automatically-collected information to identify you personally without additional consent.
          </p>
          <p>
            We use cookies and similar tracking tools to collect this information. Cookies represent very few text files kept on your computer or hardware when you visit our
            websites. Some cookies are critical for the Website to function; others improve your experience on the Site and permit us provide you with a better service. The cookie
            types we use, and their purposes are listed below.
          </p>
          <p>Required cookies: Enable the navigation and basic functions of websites, for example, access to specific website sections.</p>
          <p>
            Functional cookies: allow us to analyze how the Website is used and the choices on the Website (for example, session key, language, or location), then we can save these
            settings and offer a more personalized experience.
          </p>
          <p>
            Advertising cookies: allow us to evaluate the effectiveness of our marketing content. Our partners provide these cookies files to track website visits and register new
            players. We do not reveal your personal information (such as your name or e-mail address) with affiliate partners, except for site visit data. However, your visit data
            may be linked to other personal data providers have collected from other sources. The processing of this data is governed by the third-party providers' personal notices
            and privacy policies.
          </p>
          <p>
            In addition to the above, we use other service providers who also set cookies on this Website to provide their services. The services of this kind may include (but are
            not limited to):
          </p>
          <ul>
            <li>Helping to improve your experience by tracking your activities on the Site.</li>
            <li>Evaluating the effectiveness of the Website.</li>
            <li>The effectiveness of our marketing campaigns.</li>
          </ul>
          <p>
            Most web browsers automatically accept cookies. If you think that they are undesirable, you can turn off some or all cookies or remove all cookies that have already
            been set by changing your browser settings. However, we recommend that you do not block or delete cookies, as this may restrict our website's use.
          </p>
          <Link className="goback-link main-btn" to={page}>
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
