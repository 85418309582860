import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useUser } from "../../customHooks/userHooks";

import "./TopGame.css";

function TopGame() {
  let navigate = useNavigate();
  const { openRegisterPopup } = useContext(UserContext);
  const { canStartPlay } = useUser();

  return (
    <div className="lp-top-game-block">
      <div className="lp-top-game-img-container">
        <img width="1336" height="1397" src="/assets/images/lp/lp-top-game-bg.svg" className="lp-top-game-banner" alt="banner" />
        <div className="lp-top-game-img-empty"></div>
      </div>
      <div className="lp-top-game-text-container">
        <div className="lp-top-game-text-inner-container main-container">
          <div className="lp-top-game-text-empty"></div>
          <div className="lp-top-game-text-block">
            {window.innerWidth > 1025 ? (
              <h1 className="lp-top-game-title title">The Playing Piano Club - dive into the US of the 20s</h1>
            ) : (
              <h1 className="lp-top-game-title title">
                The Playing Piano Club -<br /> dive into the US of the 20s
              </h1>
            )}
            <div className="game-image-block">
              <div className="hover-img">
                <button
                  onClick={() => {
                    navigate(`/play/11`);
                  }}
                >
                  Play now
                </button>
              </div>
              <img width="866" height="581" src="/assets/images/home/top-game-main.webp" className="lp-top-game-text-banner" alt="banner" />
            </div>
            <p className="lp-top-game-text">
              Now is the time for the elegance of good music to collide with the thrill of modern entertainment at The Playing Piano Club. Take your exclusive ticket to a symphony
              of excitement and start playing right now! Our slot machine isn't just a game; it's a stunning performance waiting to unfold at your fingertips. Each spin is a
              crescendo building to a euphoric climax that will leave you tapping your toes and begging for more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopGame;
