import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import ContactForm from "../components/forms/ContactForm";

import "./Contact.css";

function Contact() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Winnerplayslots - Contact";

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight);
  }, [setContentHeight]);

  return (
    <div className="text-page-body contact-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="contact-mobile-container">
        <div className="contact-image-container">
          <div className="empty-image-block"></div>
          <div className="contact-image-cover">
            <img className="contact-image" src="/assets/images/contact-main.webp" alt="contact-background" />
          </div>
        </div>
        <div className="contact-form-cover">
          <div className="main-container">
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
          <div className="empty-form-block"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
