import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import "./OptOut.css";

function OptOut() {
  const [contentHeight, setContentHeight] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  // const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');

  const fetchData = async (dataObj) => {
    try {
      setIsLoading(true);
      const response = await fetchFormData('/api/optout.php', dataObj);
      if (typeof response.status !== "undefined" && response.status != null && response.status === "success") {
        setIsLoading(false);
        setShowMessage(true);
        setMessageText(response.message);
        setName('');
        setEmail('');
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setMessageText(response.message);
        setTimeout(() => {
          setMessageText('');
          setName('');
          setEmail('');
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setMessageText('Sorry, please try again later...');
      setTimeout(() => {
        setMessageText('');
        setName('');
        setEmail('');
      }, 3000);
      console.error('Error:', error);
    }
  };


  let isFormValid = false;
  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = 'Name is required.';
    }

    if (!email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid.';
    }

    setErrors(errors);
    // setIsFormValid(Object.keys(errors).length === 0);
    isFormValid = Object.keys(errors).length === 0;
  };
  // Submit
  const handleSubmit = async () => {
    validateForm();

    if (isFormValid) {
      const data = {name: name, email: email, received: new Date().toJSON().slice(0, 10)}
      await fetchData(data);
    }
  };

  function fetchFormData(url, param) {
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
      body: new URLSearchParams(param)
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  useEffect(() => {
    document.title = "Winnerplayslots - Contact";

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight);
  }, [setContentHeight]);

  return (
    <div className="text-page-body opt-out__contact-body opt-out-cover" style={{ minHeight: `${contentHeight}px` }}>
      <div className="opt-out">
        <h3>Opt-Out Instructions:</h3>
        <p>If you wish to opt-out of our use of your personal information, particularly for marketing purposes, please contact us using the form provided on our website.</p>
        <h3>Compliance with Legal Obligations:</h3>
        <p>Winnerplayslots complies with all applicable laws and regulations regarding data protection and privacy.</p>
      </div>
      <div className="contact-mobile-container">
        <div className="contact-image-container">
          <div className="empty-image-block"></div>
          <div className="contact-image-cover">
            <img className="contact-image" src="/assets/images/contact-main.webp" alt="contact-background" />
          </div>
        </div>
        <div className="optout-form-cover">
          <div className="main-container">
            <div className="optout-form">
              <>
                {isLoading && <p>Loading...</p>}
                <form>
                  <label htmlFor="name">
                    <input value={name}
                           onChange={(e) => setName(e.target.value)}
                           type="text"
                           id="name-c"
                           placeholder="Name"
                    />
                    {errors.name && <div className="error_message">{errors.name}</div>}
                  </label>

                  <label htmlFor="email-c">
                    <input value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           type="email"
                           id="email-c"
                           placeholder="Email"
                    />
                    {errors.email && <div className="error_message">{errors.email}</div>}
                  </label>
                  <span className="main-btn"
                          // style={{opacity: isFormValid ? 1 : 0.5 }}
                          // disabled={!isFormValid}
                          onClick={handleSubmit} >
                    Submit
                  </span>
                </form>
                {showMessage && <div className="show-success-message">{messageText}</div>}
              </>
            </div>
          </div>
          <div className="empty-form-block"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OptOut;
