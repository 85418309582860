import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useUser } from '../../customHooks/userHooks';
import { lpGameLinkArr } from '../../data/gamesUrlList';

import './TopGames.css';

function TopGames() {
  let navigate = useNavigate();
  const { openRegisterPopup } = useContext(UserContext);
  const { canStartPlay } = useUser();

  return (
    <>
      <div className={`lp-top-games-block${window.innerWidth <= 767 ? ' main-container' : ''}`}>
        {window.innerWidth > 1025 ?
          <img width='243' height='172' className='lp-top-games-block-background' src="/assets/images/home/top-games-bg.svg" alt="top games background" /> :
          null
        }
        <div className="lp-top-games-title-block">
          <div className="title">EVERY COOL GAME YOU’VE DREAMED OF</div>
          <img className='lp-top-games-title-left' src="/assets/images/home/top-game-title.webp" alt="decoration" />
          <img className='lp-top-games-title-right' src="/assets/images/home/top-game-title.webp" alt="decoration" />
        </div>
        <div className="lp-top-game-icons-container main-container">
          <div className='lp-top-game-icons-block'>
            {[...Array(4).keys()].map(game =>
              <div className="game-image-block" key={game}>
                <div className='hover-img'>
                  <button onClick={() => { navigate(`/play/${game}`); }}>Play now</button>
                </div>
                <img width='433' height='433' src={`/assets/images/lp_games/lp-game-${lpGameLinkArr[game + 1]}.webp`} alt="game" />
              </div>
            )}
          </div>
          <div className='lp-top-game-icons-block middle-block'>
            {[...Array(3).keys()].map(game =>
              <div className="game-image-block" key={game}>
                <div className='hover-img'>
                  <button onClick={() => { navigate(`/play/${game + 4}`); }}>Play now</button>
                </div>
                <img width='583' height='305' src={`/assets/images/lp_games/lp-game-${lpGameLinkArr[game + 5]}.webp`} alt="game" />
              </div>
            )}
          </div>
          <div className='lp-top-game-icons-block'>
            {[...Array(4).keys()].map(game =>
              <div className="game-image-block" key={game}>
                <div className='hover-img'>
                  <button onClick={() => { navigate(`/play/${lpGameLinkArr[game + 7]}`); }}>Play now</button>
                </div>
                <img width='433' height='433' src={`/assets/images/lp_games/lp-game-${game + 8}.webp`} alt="game" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TopGames;
