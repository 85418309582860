import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import "./Legal.css";

function Privacy() {
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    document.title = "Winnerplayslots - Privacy Policy";

    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return (
    <div className="text-page-body legal-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover main-container">
        <h1 className="title">Privacy Policy</h1>
        <h3>1. SCOPE</h3>
        <p>This Privacy Policy (hereinafter referred to as "This Policy ") shows the Company's scope to process information and personal data that you have submitted. It enables our effective management of the relationship between you and the Company.</p>
        <p>This Policy applies to our websites, applications, products, and/or services that link to this policy or do not have a separate privacy policy (referred to as hereinafter "Our Services"). The purpose of this Privacy Policy is an explanation of what data we collect and for what reasons we collect data, and any other relevant issues related to privacy and security.</p>
        <p>Any personal information that you provide will be processed following this Policy.
          If you disagree with the terms of this Privacy Policy, do not use this website or share with us your personal data.</p>

        <h3>2. DEFINITIONS</h3>
        <p>"Data Controller" means a natural or legal person or public office, agency, or another body that alone or together with other entities determines goals and methods of data processing.</p>
        <p>"Data Processor" means an individual or legal authority, public authority, agency, or other body that is processing data on behalf of our administrator.</p>
        <p>"Personal Data" is any information making it possible to identify you individually.</p>

        <h3>3. PERSONAL DATA PROTECTION</h3>
        <p>The Personal Data we store are protected by using top-class security processes and systems.</p>
        <p>Our obligation to protect personal data does not lie only on quality and high standards, but also on the law's best and most effective application.</p>

        <h3>4. PROCESSING BASED ON OUR INTERESTS</h3>
        <p>A legitimate interest exists where we have a business or commercial reason to process personal data. In this case, we are committed to protecting any of your data to ensure that such processing is not unfair to you or your interests. If and when we decide to process your personal data based on a legitimate interest, we will inform you about the objections related to such data processing. Please note that the Company is not obliged to stop processing if the grounds for processing outweigh your right to object.</p>

        <h3>5. CONSENT BASED PROCESSING</h3>
        <p>To avoid doubt, we would like to point out that in those limited cases where we cannot choose not to rely on another legal ground (for example, our legitimate interests), we will process your Personal Data based on your consent. In cases where we process your consent (which we will never assume in advance, but which we obtain from you clearly), YOU HAVE THE RIGHT TO WITHDRAW YOUR CONSENT AT ANY TIME in the same way it was originally given.</p>

        <h3>6. COOKIES</h3>
        <p>When you visit our Website, we automatically collect specific categories of personal data using cookies and similar technologies. When using the Website, a cookie will be saved on your computer. In general, cookies and similar technologies work by assigning a unique number to your browser or device that is irrelevant outside Our Site.</p>
        <p>These technologies enable personalization of the use of the Website, including language and currency preferences, and help provide content tailored to your interests. In addition, after entering your email address and password during a session on the Website, we save this information so that you do not have to enter it repeatedly during this session. If you consent, it can be extended by information using cookies or other equivalent technology; you can use the settings in your browser or mobile device. We strive to provide you with the ability to manage your privacy and data sharing.</p>

        <h3>7. OUR GOALS</h3>
        <p>We may be required to use and store personal information to prevent loss and protect our rights, privacy, safety, property, or others according to our legitimate interests.</p>

        <h3>8. PERMITTED DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES</h3>
        <p>Without prejudice to any other content in this privacy policy, we may share Personal Data relating to the user with authorized bodies in places where such disclosure is permitted or required under data protection law and/or other applicable regulations. These authorized third parties may include but are not limited to entities within the Company, other third parties, and organizations such as law enforcement agencies, cooperating accounting and auditing firms, regulators, relevant authorities.</p>
        <p>We may also share such personal information with the organizations that brought you to us, third parties you have asked for, or allow us to share your data. There can be other third parties with whom we must share your data to provide the products and/or services you request. The personal information provided depends on the product (s) and/or services you choose to use.</p>

        <h3>9. SHARING PERSONAL DATA WITH OTHER CATEGORIES OF RECIPIENTS</h3>
        <p>Relevant data will also be disclosed or shared, where applicable (and in all cases following the Data Protection Laws), to members and employees of the Company, other entities within the Company (for example, under legal obligations) and/or affiliates and/or subcontractors. If they are related to any of the purposes mentioned in this Privacy Policy (including service providers responsible for the proper functioning of the website and/or services that you may require). We will only share personal data to provide the services you expect from us or for other legal reasons (including disclosures that do not need your consent).</p>

        <h3>10. ACCURACY OF PERSONAL DATA</h3>
        <p>We make every effort to ensure that all Personal Data we may store concerning you is up-to-date and as accurate as possible. You can check the information we hold about you at any time by contacting us as set out below. If you find any inaccuracies, we will correct them and correct them if necessary.</p>

        <h3>11. LINKS TO THIRD PARTIES</h3>
        <p>The links we provide to third-party websites are marked, and we are not responsible in any way for (nor can be considered an endorsement for any data processing). We suggest you to read the privacy policies of all such third-party websites.</p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
