import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useUser } from "../customHooks/userHooks";
import SignInPopup from "./forms/SignInPopup";
import ThankYouPopup from "./ThankYouPopup";
import AgePopup from "./AgePopup";
import "./Header.css";
import SendThriveRequest from "./SendThriveRequest";

function Header() {
  const { pathname } = useLocation();
  const {isOneGame, isLoading, countryData, mobMenu, showRegisterPopup, showThanksPopup, cookies, loginPopupStatus, openRegisterPopup, userLogout, toggleMobMenu, showAgePopup, setShowAgepopup } =
    useContext(UserContext);
  const { showRegisterBtn } = useUser();
  const [docWidth, setDocWidth] = useState(false);
  const [isLP, setIsLP] = useState(false);
  const [is404, setIs404] = useState(false);
  const [isCookie, setIsCookie] = useState(false);

  useEffect(() => {
    if (cookies.is18 === "false" && pathname === "/cookies/") {
      setShowAgepopup(false);
      setIsCookie(true);
    } else if (cookies.is18 === "true") {
      setShowAgepopup(false);
      setIsCookie(false);
    } else {
      setShowAgepopup(true);
      setIsCookie(false);
    }

    if (pathname === "/gb/" || pathname === "/gb") {
      setIsLP(true);
    } else {
      setIsLP(false);
    }

    if (pathname === "/page404/" || pathname === "/page404") {
      setIs404(true);
    } else {
      setIs404(false);
    }

    setDocWidth(document.body.clientWidth);
  }, [cookies.is18, pathname]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <>{showAgePopup ? <AgePopup /> : null}</>
      {/* <SendThriveRequest secretCookies={secretCookies} /> */}
      <SendThriveRequest />

      <header className="header-section">
        <div className="header-section-disclaimer">Social game fun: no money needed. For fun only!</div>
        <nav className={`header-section-cover ${docWidth > 1025 ? "main-container" : ""}`}>
          <>
            {!isLP && !isCookie ? (
              <Link to="/">
                <img width="165" height="67" src="/assets/images/logo.webp" className="logo" alt="logo" />
              </Link>
            ) : (
              <img width="165" height="67" src="/assets/images/logo.webp" className="logo" alt="logo" />
            )}
          </>

          <>
            {!isCookie && !is404 ? (
              !isLP ? (
                <ul className={mobMenu ? "nav-list-section show-mobile-menu" : "nav-list-section"}>
                  <li className="nav-logo-mobile">
                    <img width="165" height="67" src="/assets/images/logo.webp" className="logo" alt="logo" />
                  </li>
                  <li>
                    <NavLink to="/" onClick={toggleMobMenu}>
                      Home
                    </NavLink>
                  </li>
                  {!isOneGame && (
                      <>
                        <li>
                          <NavLink to="/all-games/" onClick={toggleMobMenu}>
                            All Games
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/top5/" onClick={toggleMobMenu}>
                            Top 5 Games
                          </NavLink>
                        </li>
                      </>
                  )}
                  <li>
                    <NavLink to="/about/" onClick={toggleMobMenu}>
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact/" onClick={toggleMobMenu}>
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/disclaimer/" onClick={toggleMobMenu}>
                      Disclaimer
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/opt-out/" onClick={toggleMobMenu}>
                      Opt Out
                    </NavLink>
                  </li>
                  <>
                    {showRegisterBtn && mobMenu && docWidth <= 1025 ? (
                      <>
                        <li>
                          <button className="login-btn mobile-menu-login-btn" onClick={() => openRegisterPopup(true)}>
                            Log In
                          </button>
                        </li>
                        <li>
                          <button className="register-btn" onClick={() => openRegisterPopup(false)}>
                            Sign In
                          </button>
                        </li>
                      </>
                    ) : null}
                  </>
                  <>
                    {!showRegisterBtn && mobMenu && docWidth <= 1025 ? (
                      <li>
                        <div className="login-btn mobile-menu-login-btn" onClick={userLogout}>
                          Log out
                        </div>
                      </li>
                    ) : null}
                  </>
                </ul>
              ) : (
                <ul className={mobMenu ? "nav-list-section show-mobile-menu" : "nav-list-section"}>
                  <>
                    {showRegisterBtn && mobMenu ? (
                      <>
                        <li className="nav-logo-mobile">
                          <img width="165" height="67" src="/assets/images/logo.webp" className="logo" alt="logo" />
                        </li>
                        <li>
                          <button className="page login-btn" onClick={() => openRegisterPopup(true)}>
                            Log In
                          </button>
                        </li>
                        <li>
                          <button className="page register-btn" onClick={() => openRegisterPopup(false)}>
                            Sign In
                          </button>
                        </li>
                      </>
                    ) : null}
                  </>
                  <>
                    {!showRegisterBtn && mobMenu && docWidth <= 1025 ? (
                      <li>
                        <div className="login-btn mobile-menu-login-btn" onClick={userLogout}>
                          Log out
                        </div>
                      </li>
                    ) : null}
                  </>
                </ul>
              )
            ) : null}
          </>

          <>
            {!isCookie && !is404 ? (
              showRegisterBtn ? (
                <>
                  {docWidth > 1025 ? (
                    <div className="btns-container">
                      <button className="register-btn" onClick={() => openRegisterPopup(false)}>
                        Sign In
                      </button>
                      <button className="login-btn" onClick={() => openRegisterPopup(true)}>
                        Log In
                      </button>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {docWidth > 1025 ? (
                    isLP ? (
                      <button className="page register-btn" onClick={userLogout}>
                        Log Out
                      </button>
                    ) : (
                      <button className="register-btn" onClick={userLogout}>
                        Log Out
                      </button>
                    )
                  ) : null}
                </>
              )
            ) : null}
          </>

          <>
            {!isCookie && !is404 ? (
              <button className="mobile-menu-btn" onClick={toggleMobMenu}>
                <>
                  {mobMenu ? (
                    <img width="40" height="24" src="/assets/images/close-icon.svg" alt="" className="close-icon" />
                  ) : (
                    <img width="40" height="24" src="/assets/images/menu-icon.svg" alt="" className="menu-icon" />
                  )}
                </>
              </button>
            ) : null}
          </>
        </nav>

        <>{showRegisterPopup ? <SignInPopup countryData={countryData} loginPopupStatus={loginPopupStatus} /> : null}</>

        <>{showThanksPopup ? <ThankYouPopup /> : null}</>
      </header>
    </div>
  );
}

export default Header;
