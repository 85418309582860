import React, {createContext, useEffect, useState} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { logout } from '../data/dataApi';
import Cookies from "js-cookie";
import useSecretCookies from "../customHooks/useSecretCookies";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [loginPopupStatus, setLoginPopupStatus] = useState(false);
  const [mobMenu, setMobMenu] = useState(false);
  const [cookies, setCookie] = useCookies(['is18']);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [countryData, setCountryData] = useState({code: 'US', country: 'USA'});
  const { setSecretCookies } = useSecretCookies();
  const [isOneGame, setIsOneGame] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkIsOneGame = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('/api/site/params.php');
                const data = await response.json();

                if (data.one_game === 'true' || data.one_game === true) {
                    setIsOneGame(true);
                } else {
                    setIsOneGame(false);
                    throw new Error('ONE_GAME variable not found');
                }
            } catch (error) {
                console.error('Error fetching config:', error);
                setIsOneGame(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkIsOneGame();
    }, []);

    function uet_report_conversion() {
        window.uetq = window.uetq || [];
        window.uetq.push('event', 'signup', {});
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`);
                if (response.ok) {
                    const data = await response.json();

                    setSecretCookies('sc_geo', {
                        address_country: data.country_name,
                        address_city: data.city,
                        address_state: data.region,
                        address_zip: data.postal
                    });

                    const country_data = {
                        code: data.country_code,
                        country: data.country_name
                    };
                    setCountryData(country_data);

                } else {
                    console.error('Response error:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, []);

  const toggleMobMenu = () => {
    setMobMenu(!mobMenu);
    console.clear();
  };

  const openRegisterPopup = (login = false) => {
    if (login) {
      setShowRegisterPopup(true);
      setLoginPopupStatus(true);
      setMobMenu(false);
    } else {
      setShowRegisterPopup(true);
      setLoginPopupStatus(false);
      setMobMenu(false);
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
    setLoginPopupStatus(false);
  };

  const userRegistered = () => {
    uet_report_conversion();
    closeRegisterPopup();
    setShowThanksPopup(true);
  };

  const userLogged = () => {
    closeRegisterPopup();
    // window.location.reload();
  };

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    if (pathname === '/gb/' || pathname === '/gb') {
      window.location.href = "/gb/";
    } else if (pathname === '/top10/' || pathname === '/top10') {
      window.location.href = "/top10/";
    } else {
      navigate(0);
    }
  };

  const userLogout = async () => {
    const url = logout();
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        navigate(0);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


    return (
    <UserContext.Provider
      value={{
        mobMenu,
        showRegisterPopup,
        setShowRegisterPopup,
        showThanksPopup,
        setShowThanksPopup,
        cookies,
        setCookie,
        showRegisterBtn,
        setShowRegisterBtn,
        openRegisterPopup,
        closeRegisterPopup,
        userRegistered,
        userLogged,
        closeThanksPopup,
        loginPopupStatus,
        userLogout,
        toggleMobMenu,
        showAgePopup,
        setShowAgepopup,
        countryData,
          isOneGame,
          isLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
