import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { gamesUrlList } from "../data/gamesUrlList";
import Footer from "../components/Footer";

import "./Play.css";

function Play({ is18 }) {
  let navigate = useNavigate();

  let { gameUrl } = useParams();
  const [url, setUrl] = useState();
  // const [goBackLink, setGoBackLink] = useState('/');
  const [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    const contentBlock = document.querySelector(".text-page-body");
    setContentHeight(contentBlock.clientHeight);
  }, [setContentHeight]);

  useEffect(() => {
    document.title = "Winnerplayslots - Play Game";

    if (gameUrl >= 52 || isNaN(gameUrl)) {
      navigate("/page404/");
    }

    if (gameUrl) {
      setUrl(gamesUrlList[gameUrl]);
    } else {
      setUrl(gamesUrlList[0]);
    }
  }, [setUrl, gameUrl, is18, navigate]);

  const clearConsole = () => {
    console.clear();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-page-body" style={{ minHeight: `${contentHeight}px` }}>
      <div className="content-cover main-container">
        <div
          className="game-back-link"
          onClick={() => {
            clearConsole();
            goBack();
          }}
        >
          <i className="fas fa-arrow-left"></i>Back
        </div>

        <div className="game-cover">
          <iframe title="game" src={url} frameBorder="0"></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Play;
