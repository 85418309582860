import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import './AgePopup.css';

function ThankYouPopup() {
  const { pathname } = useLocation();
  const [isAgeTerms, setIsAgeTerms] = useState(true);
  const [blockContent, setBlockContent] = useState(false);
  const { setCookie, setShowAgepopup } = useContext(UserContext);

  const checkAgeTerms = () => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if (termsChecked !== 'not set') {
      setIsAgeTerms(true);
    } else {
      setIsAgeTerms(false);
    }
  };

  const hideAgePopup = (e) => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if (isAgeTerms && termsChecked === 'yes') {
      // if (isAgeTerms) {
      setCookie('is18', true, { path: '/' });
      setShowAgepopup(false);
      // navigate(`/play/${theGame}`);
      setIsAgeTerms(true);
    } else {
      setIsAgeTerms(false);
    }
  };

  const closeAgePopup = () => {
    setBlockContent(true);
    // setTheGame('');
    // setShowAgepopup(false);
  };

  const openCookiePage = () => {
    localStorage.setItem('page', pathname);
  };

  return (
    <div className="show-18-popup">
      <div className="popup-18-overlay"></div>
      <div className="popup-18-body">
        {!blockContent ?
          <>
            <h2 className='title'>Over 21 years old ?</h2>
            <div className="checkbox-cover">

              <label htmlFor="terms-18">
                <input type="checkbox" name="terms-18" id="terms-18" value="yes" onChange={checkAgeTerms} />I agree with <Link target="_blank" to="/cookies/" onClick={openCookiePage}>Cookies Policy</Link>
                <span className="box checkbox"></span>
              </label>

              <label className="hidden">
                <input type="checkbox" name="terms-18" value="not set" defaultChecked />
                <span className="box checkbox"></span>
              </label>

              <div className={!isAgeTerms ? 'show-error' : 'hide-error'}>This checkbox is required</div>
            </div>

            <div className="buttons">
              <button id="button-yes" onClick={hideAgePopup}>Yes</button>
              <button id="button-no" onClick={closeAgePopup}>No</button>
            </div>
          </> :
          <div className='no-age-block'>
            <img width='241' height='219' src="/assets/images/no-age-bg.webp" className="no-age-bg" alt="" />
            <h2 className='title age-no-title'>Sorry, we can’t let you play our game :(</h2>
          </div>
        }

      </div>
    </div>
  );
}

export default ThankYouPopup;
