import React, { useEffect } from "react";
import Footer from "../components/Footer";
import "./PageNotFound.css";

function PageNotFound() {
  useEffect(() => {
    document.title = "Winnerplayslots - 404";
  }, []);

  return (
    <div className="page-not-found-container">
      <div className="page-not-found-block">
        <img className="page-not-found-img" src="/assets/images/404.webp" alt="" />
        <div className="page-not-found-main-img">
          <img src="/assets/images/404-main.webp" alt="" />
        </div>
      </div>
      <Footer className={"page-not-found-footer"} />
    </div>
  );
}

export default PageNotFound;
